import { Duration, Organization, DashboardPageModel } from '@models';
import { createActionGroup, props } from '@ngrx/store';

export const dashboardActions = createActionGroup({
  source: '[Dashboard page]',
  events: {
    'Visitor Count': props<{ filter: Record<string, unknown> }>(),
    'Query Count': props<{
      filter: Record<string, unknown>;
      organizationId: Organization['id'];
    }>(),
    'Bot Count': props<{ filter: Record<string, unknown> }>(),
    'Bot Count Success': props<{
      data: DashboardPageModel.BotEngageTimeResponse;
    }>(),
    'New Visitor Count': props<{ filter: Record<string, unknown> }>(),
    'New Visitor Count Success': props<{
      data: DashboardPageModel.NewVisitorCount;
    }>(),
    'Visitor Count Success': props<{
      data: DashboardPageModel.VisitorCountResponse;
    }>(),
    'Query Count Success': props<{
      data: DashboardPageModel.QueryCountResponse;
    }>(),
    'Leads Count': props<{ filter: Record<string, unknown>; orgId: string }>(),
    'Leads Count Success': props<{ data: DashboardPageModel.LeadResponse }>(),
    'Complaints Count': props<{
      duration: Duration;
      orgId: string;
    }>(),
    'Complaints Count Success': props<{
      data: DashboardPageModel.ComplaintCountResponse[];
    }>(),
  },
});
