import { createFeatureSelector, createSelector } from '@ngrx/store';
import { DashboardState } from './dashboard.state';
export const DASHBOARD_STATE_NAME = 'dashboard';
export const selectDashboardState =
  createFeatureSelector<DashboardState>(DASHBOARD_STATE_NAME);

export const selectVisitorCount = createSelector(
  selectDashboardState,
  state => state.visitorCount
);

export const selectQueryCount = createSelector(
  selectDashboardState,
  state => state.queryCount
);

export const selectLeadCount = createSelector(
  selectDashboardState,
  state => state.leadCount
);

export const selectComplainCount = createSelector(
  selectDashboardState,
  state => state.complainCount
);

export const selectBotCount = createSelector(
  selectDashboardState,
  state => state.botCount
);
export const selectNewVisitorCount = createSelector(
  selectDashboardState,
  state => state.newVisitorCount
);
